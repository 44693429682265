import {api} from "boot/axios";
import {getSelectedCompany} from "src/api/helpers";

export async function getThreads(archived) {
  const selectedCompany = getSelectedCompany()
    const {status, data, error} = await api.get(`companies/${selectedCompany}/threads`, {
      params: {
        a: archived,
      }
    });
    return  {status, data, error};
}

export async function createThread(name, threadable_id) {
  const selectedCompany = getSelectedCompany()
    const {status, data, error} = await api.post(`companies/${selectedCompany}/threads`, {
        name,
        threadable_id,
        threadable_type:threadable_id ? 'attachment' : null //in the future this will vary
    });

  return {status, data, error};
}

export async function RenameThread(id, name) {
  const selectedCompany = getSelectedCompany()
  const {status, data, error} = await api.put(`companies/${selectedCompany}/threads/${id}`, {
    name
  });

  return {status, data, error};
}
export async function ArchiveThread(id, archive) {
  const selectedCompany = getSelectedCompany()
  const {status, data, error} = await api.put(`companies/${selectedCompany}/threads/${id}`, {
    archive
  });

  return {status, data, error};
}

export async function readThread(thread_id, options = {}) {
  const selectedCompany = getSelectedCompany()
  const {receiveUpdatedUsersSeenThread = false} = options || {};

  const {status, data, error} = await api.put(`companies/${selectedCompany}/threads/${thread_id}/read`,{
    "receive_updated_users_seen_thread": receiveUpdatedUsersSeenThread
  });

  return {status, data, error};
}

export async function getAttachmentBreadcrumbs(attachment_id) {
    const selectedCompany = getSelectedCompany()
    const {status, data, error} = await api.get(`companies/${selectedCompany}/storage/attachments/${attachment_id}/breadcrumbs`);

    return {status, data, error};
  }

  export async function saveAttachmentToStorage(attachment_id) {
    const selectedCompany = getSelectedCompany()
    const {status, data, error} = await api.get(`companies/${selectedCompany}/storage/attachments/${attachment_id}/saveToStorage`);

    return {status, data, error};
  }

  export async function getThreadWithAttachments(thread_id) {
    const selectedCompany = getSelectedCompany()
      const {status, data, error} = await api.get(`companies/${selectedCompany}/threads/${thread_id}`);
      return  {status, data, error};
  }
