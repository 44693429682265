import { defineStore } from 'pinia'
import { getUser } from "src/api/users";
import { useCompanyStore } from './company';
import { useRoute } from 'vue-router';
import { useFirmStore } from './firm';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null
  }),

  getters: {
    getUser(state) {
      return state.user
    },
    getUserableSocketChannel(state) {
      switch (state.user.type) {

        case 'company':
          return `companies.${useCompanyStore().getCurrentCompanyId}`;

        case 'accountant':
          return `firms.${ useFirmStore().getCurrentFirm.id }`;

        default:
          return null;
      }
    }
  },

  actions: {
    async setUser() {
      this.user = await getUser();
      if (useCompanyStore().getCurrentCompanyId)
        useCompanyStore().setUserTypeAndUserableId();
    },
    setUserManually(user) {
      this.user = user;
    },
    incrementNotificationCount() {
      this.user.unread_notifications_count++;
    },
    decrementNotificationCount() {
      this.user.unread_notifications_count--;
    },
    setUserType(type) {
      this.user.type = type;
    },
    setUserableId(id) {
      this.user.userable_id = id;
    }
  }
})