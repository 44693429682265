import {api} from "boot/axios";
import { getSelectedCompany } from "./helpers";

export async function getAccountantDetails() {
  const selectedCompany = getSelectedCompany();
  const {status, data, error} = await api.get(`companies/${selectedCompany}/accountant`);
  return  {status, data, error};
}

export async function disassociateAccountant() {
  const selectedCompany = getSelectedCompany();
  const {status, data, error} = await api.post(`companies/${selectedCompany}/disassociate`);
  return  {status, data, error};
}

export async function getCompany() {
  const selectedCompany = getSelectedCompany();
  const { status, data, error } = await api.get(`companies/${selectedCompany}`);
  return { status, data, error };
}

export async function getCompanies(filters,pagination = {}) {

  const params = {
    keyword: filters.keyword,
    ability: filters.ability,
    dontHaveFirm: filters.doesntHaveFirm,
  }

  if (pagination.value) {
    params.page =   pagination.value.page
    params.perPage =   pagination.value.perPage
  }

  let { status, data, error } = await api.get(`companies`,
    {
      params: params
    });

  const meta = data.meta;
  data = data.data;
  return { status, data, meta, error };
}

/**
 *
 * @param {string} accountant_id
 * @param {{'name','phone_number','company_name','country_id','state_id','street','zip','meta_data':{} }} profileInformation
 * @returns
 */
export async function updateCompany(profileInformation) {
  let selectedCompany = getSelectedCompany();
  const { status, data, error } = await api.put(
    `companies/${selectedCompany}`,
    profileInformation
  );
  return { status, data, error };
}

export async function deleteCompany() {
  const selectedCompany = getSelectedCompany();
  const {status, data, error} = await api.delete(`/companies/${selectedCompany}`);
  return {status, data, error};
}