import {api} from "boot/axios";
import {getSelectedCompany} from "src/api/helpers";

export async function getMessages(thread_id, cursor) {
  const selectedCompany = getSelectedCompany()
  const {status, data, error} = await api.get(`companies/${selectedCompany}/threads/${thread_id}/messages`, {
    params: {
      cursor
    }
  });

  return {status, data, error};
}

export async function sendMessage(thread_id, message) {
  const selectedCompany = getSelectedCompany()
  const {status, data, error} = await api.post(`companies/${selectedCompany}/threads/${thread_id}/messages`, message);

  return {status, data, error};
}
