import {canManageCompany} from '../api/users';

export default async function CanUserManageCompany({store, next}) {
  const status = await canManageCompany();
  if(status) {
    return next();
  } else {
    next({name: "home"});
    return;
  }
}
