import {boot} from 'quasar/wrappers'
import {createI18n} from 'vue-i18n'
import messages from 'src/i18n'


let i18n = createI18n({});

export default boot(({app}) => {
  i18n = createI18n({
    silentTranslationWarn: true,
    missingWarn: true,
    fallbackWarn: false,
    locale:  process.env.app_locale,
    fallbackLocale: 'fr',
    globalInjection: true,
    legacy: false,
    messages
  })
  // Set i18n instance on app
  app.use(i18n)
})

export {i18n}