import {defineStore} from 'pinia'
import { useUserStore } from './user';
import { getAccountantCompanies } from 'src/api/accountant';

export const useFirmStore = defineStore('firm', {
  state: () => ({
    currentFirm:null
  }),

  getters: {
    getCurrentFirm(state){
      return state.currentFirm
    }
  },

  actions: {
    async refreshCurrentFirm(){
      await this.setCurrentFirm(this.currentFirm.id)
    },
    async setCurrentFirm(firmId) {
      await this.fetchCurrentFirm(firmId);
      this.setUserTypeAndUserableId(this.getCurrentFirm);
    },
    async fetchCurrentFirm(firmId){
      let { data } = await  getAccountantCompanies(firmId);
      this.currentFirm = data.accountant;
    },
    setUserTypeAndUserableId(firm){
      let type = firm.is_owned_by_me ? 'firm_owner' : 'accountant';
      useUserStore().setUserType(type);
      useUserStore().setUserableId(firm.id);
    },
    setCurrentFirmManually(firm) {
      this.currentFirm = firm;
    },

  }
})