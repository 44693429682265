{
  "Ressource non trouvée": "Resource not found",
  "retour": "return",
  "accueil": "home",
  "une erreur s'est produite": "an error occurred",
  "My Space": "My Space",
  "Supprimer le compte": "Delete account",
  " Votre compte sera définitivement supprimé dans": " Your account will be permanently deleted in",
  "60 jours": "60 days",
  "Si vous vous connectez pendant cette période, votre compte sera automatiquement": "If you log in during this period, your account will be automatically",
  "restauré": "restored",
  "Besoin d'aide ?": "Need help?",
  "Avertissement de suppression": "Deletion warning",
  "Supprimer": "Delete",
  "Vous avez encore des fichiers en cours de téléchargement, êtes-vous sûr de vouloir annuler cette action ?": "You still have files being uploaded, are you sure you want to cancel this action?",
  "Annuler le téléchargement": "Cancel upload",
  "Continuer le téléchargement": "Continue upload",
  "Catégorie de document": "Document category",
  "Ce fichier n'a pas de catégorie, veuillez ajouter une catégorie à ce fichier": "This file has no category, please add a category to this file",
  "La modification d'une catégorie de document traité peut entraîner une perte de données": "Modifying a processed document category may result in data loss",
  "Choisissez une catégorie pour la lier à votre(s) fichier(s)": "Choose a category to link to your file(s)",
  "fichiers sélectionnés": "selected files",
  "Traiter": "Process",
  "cercles": "circles",
  "Un cercle est un groupe de membres qui peut comprendre des clients, des fournisseurs, des employés et des associés.": "A circle is a group of members that can include clients, suppliers, employees, and associates.",
  "non classé": "unclassified",
  "les membres qui n'appartiennent pas à un cercle spécifique ne sont pas classés.": "members who do not belong to a specific circle are not classified.",
  "Editer le": "Edit the",
  "Nouveau": "New",
  "cercle": "circle",
  "Nom du cercle": "Circle name",
  "Cercle": "Circle",
  "Couleur": "Color",
  "Enregistrer": "Save",
  "Supprimer le cercle": "Delete circle",
  "ce cercle sera définitivement supprimé et détaché de tous ses membres": "this circle will be permanently deleted and detached from all its members",
  "Détacher le cercle": "Detach circle",
  "Êtes-vous sûr de vouloir détacher ce cercle du membre ": "Are you sure you want to detach this circle from the member ",
  "Détacher": "Detach",
  "documents": "documents",
  "Document non supporté": "Unsupported document",
  "Document non trouvé": "Document not found",
  "Nous ne prenons pas encore en charge les documents": "We do not yet support documents",
  "Ce document pourrait être archivé ou supprimé": "This document may be archived or deleted",
  "Document téléchargé avec succès dans votre documents": "Document successfully uploaded to your documents",
  "Ouvrir l'emplacement": "Open location",
  "Aperçu": "Preview",
  "Télécharger": "Download",
  "Téléverser": "Upload",
  "Rechercher": "Search",
  "Voir les invitations": "View invitations",
  "Mon profil": "My profile",
  "Déconnexion": "Logout",
  "nouvelle invitation reçue de ": "new invitation received from ",
  "Invitation rejecte par ": "Invitation rejected by ",
  "a annulé l'invitation": "has cancelled the invitation",
  "a accepté votre invitation": "has accepted your invitation",
  "Email": "Email",
  "Invitation envoyée à l'expert-comptable": "Invitation sent to the accountant",
  "Invitation envoyée à l'entreprise": "Invitation sent to the company",
  "user is not on-boarded": "user is not on-boarded",
  "Vu": "Seen",
  "Editer": "Edit",
  "Gestion des fichiers": "File management",
  "Téléchargez un fichier ou créez un dossier": "Upload a file or create a folder",
  "Créer un dossier": "Create a folder",
  "Sélectionner des fichiers": "Select files",
  "Fichiers en cours de téléchargement": "Files being uploaded",
  "Aucun document téléchargé": "No documents uploaded",
  "Aucun document n'a encore été téléchargé sur la plateforme.": "No documents have been uploaded to the platform yet.",
  "Général": "General",
  "Restez à l'écoute, pas encore de mises à jour": "Stay tuned, no updates yet",
  "Aucune mise à jour ou notification ne vous a été envoyée pour le moment.": "No updates or notifications have been sent to you yet.",
  "Voir toutes les notifications": "View all notifications",
  "L'utilisateur n'est pas enregistré": "The user is not registered",
  "Editer le membre": "Edit member",
  "Nouveau membre": "New member",
  "Nom du membre": "Member name",
  "annuler": "cancel",
  "Mettre à jour": "Update",
  "Créer": "Create",
  "Details d'agence": "Agency details",
  "nom de l'agence": "agency name",
  "Identifiant unique": "Unique identifier",
  "Adresse": "Address",
  "Pays": "Country",
  "Proviance": "Province",
  "Rue": "Street",
  "Code Postal": "Postal Code",
  "Détails de l'entreprise": "Company details",
  "Nom de l'entreprise": "Company name",
  "Taille de l'entreprise": "Company size",
  "Address": "Address",
  "Street": "Street",
  "Code postal": "Postal code",
  "Information Fiscale": "Tax Information",
  "Additional information": "Additional information",
  "Legal Information": "Legal Information",
  "Information administrative": "Administrative information",
  "Filtrer la recherche": "Filter search",
  "Membre": "Member",
  "Catégorie": "Category",
  "Date": "Date",
  "Fermer": "Close",
  "Réinitialiser": "Reset",
  "Appliquer les filtres": "Apply filters",
  "integration": "integration",
  "integration in proccess": "integration in proccess",
  "bookeeping SaaS": "bookeeping SaaS",
  "connect": "connect",
  "to": "to",
  "allows you to": "allows you to",
  "import invoices to": "import invoices to",
  "Manage your invoices": "Manage your invoices",
  "keep your accountant in sync": "keep your accountant in sync",
  "By clicking on allow access, you authorize": "By clicking on allow access, you authorize",
  "to use your information in accordance with its": "to use your information in accordance with its",
  "Privacy Policy": "Privacy Policy",
  "open": "open",
  "Détail du relevé": "Statement detail",
  "Document": "Document",
  "Vous pouvez masquer ou afficher ce champ dans le relevé": "You can hide or show this field in the statement",
  "Reference": "Reference",
  "Label": "Label",
  "Journal": "Journal",
  "Détails": "Details",
  "Cette catégorie ne supporte pas les relevés, le relevé a été supprimé.": "This category does not support statements, the statement has been deleted.",
  "Relevé supprimé": "Statement deleted",
  "Le relevé que vous êtes en train de modifier a été supprimée par un autre utilisateur.": "The statement you are modifying has been deleted by another user.",
  "Create": "Create",
  "Dossier": "Folder",
  "Envoi du fichier": "File sending",
  "Fichiers non traités": "Unprocessed files",
  "Répertoire de documents": "Document directory",
  "Sélectionnez les dossiers dans lesquels vous souhaitez inclure vos documents": "Select the folders in which you want to include your documents",
  "sélectionner": "select",
  "Rechargement...": "Reloading...",
  "Accueil": "Home",
  "Vue en liste": "List view",
  "Vue en grile": "Grid view",
  "sélectionner tout": "select all",
  "desélectionner tout": "deselect all",
  " Entamez une discussion": " Start a discussion",
  "Start thread": "Start thread",
  "Vous essayez de supprimer un dossier qui peut contenir des documents. Tous les documents seront supprimés. Êtes-vous sûr ?": "You are trying to delete a folder that may contain documents. All documents will be deleted. Are you sure?",
  "Êtes-vous sûr de vouloir supprimer ce document ?": "Are you sure you want to delete this document?",
  "Vous essayez de supprimer un ou plusieurs dossiers qui peuvent contenir des documents. Tous les documents seront supprimés. Êtes-vous sûr ?": "You are trying to delete one or more folders that may contain documents. All documents will be deleted. Are you sure?",
  "Êtes-vous sûr de vouloir supprimer": "Are you sure you want to delete",
  "Invitez votre client et gérez ses documents comptables": "Invite your client and manage their accounting documents",
  "Entrez l'email de votre client": "Enter your client's email",
  "Déplacer": "Move",
  "Copier le lien": "Copy link",
  "Détails du dossier": "Folder details",
  "Discussions": "Discussions",
  "Nom du document": "Document name",
  "Format": "Format",
  "Taille": "Size",
  "envoyer un message dans": "send a message in",
  "Unarchive this thread to respond to this message": "Unarchive this thread to respond to this message",
  "Unarchive": "Unarchive",
  "générale": "general",
  "removed": "removed",
  "Détails du fil de discussion": "Thread details",
  "informations sur ce fil de discussion": "information about this thread",
  "Sujet": "Subject",
  "general": "general",
  "Nom du fil de discussion": "Thread name",
  "copier URL": "copy URL",
  "Archiver": "Archive",
  "Aucun document envoyé": "No documents sent",
  "Aucun document n'a encore été téléchargé dans ce fil de discussion.": "No documents have been uploaded to this thread yet.",
  "Thread": "Thread",
  "Files": "Files",
  "Ajouter discussion": "Add discussion",
  "Archived threads": "Archived threads",
  "Commencer une discussion": "Start a discussion",
  "Nom de la discussion": "Discussion name",
  "Note": "Note",
  "Cette discussion sera ajoutée au sujet ": "This discussion will be added to the subject ",
  "Vous devez d'abord avoir un comptable pour créer une discussion.": "You must first have an accountant to create a discussion.",
  "File": "File",
  "Hide file": "Hide file",
  "View file": "View file",
  "Copy directory": "Copy directory",
  "Sélectionnez un fil de discussion à gauche": "Select a thread on the left",
  "File sent": "File sent",
  "New thread, send your message": "New thread, send your message",
  "Thread details": "Thread details",
  "Convertir votre document en relevés": "Convert your document to statements",
  "Traiter le document avec AI": "Process the document with AI",
  "Comptable: Commencez avec": "Accountant: Start with",
  "Aucune invitation pour le moment": "No invitation at the moment",
  "cabinet n'est invité": "cabinet is invited",
  "Votre liste d'invitations envoyées est vide": "Your list of sent invitations is empty",
  "Votre file d'attente d'invitations est actuellement vide": "Your queue of invitations is currently empty",
  "Received Invites": "Received Invites",
  "Sent Invites": "Sent Invites",
  "Tableau de bord": "Dashboard",
  "Documents": "Documents",
  "Relevés": "Statements",
  "Membres": "Members",
  "Paramètres": "Settings",
  "Alerte de dissociation": "Dissociation alert",
  "Choisissez une catégorie": "Choose a category",
  "Vous n'avez aucune entreprise dans votre espace de travail": "You have no company in your workspace",
  "Invitez vos clients pour profiter de la plateforme": "Invite your clients to benefit from the platform",
  "La dissociation avec cette entreprise signifie que vous perdrez toutes les données des clients": "Dissociating from this company means you will lose all client data",
  "Aucun fil de discussion commencé": "No discussion thread started",
  "Vous n'avez aucun fil de discussion à afficher": "You have no discussion threads to display",
  "Succès": "Success",
  "Êtes-vous sûr de vouloir dissocier": "Are you sure you want to dissociate",
  "Oops. Nothing here...": "Oops. Nothing here...",
  "Invitations": "Invitations",
  "Reçues": "Received",
  "Envoyées": "Sent",
  "Annuler l'invitation": "Cancel invitation",
  "Aucun": "No",
  "compte": "account",
  "cabinet": "firm",
  "Récupération des demandes...": "Retrieving requests...",
  "Invitez votre comptable et partagez vos documents avec": "Invite your accountant and share your documents with",
  "Entrez l'email de votre comptable": "Enter your accountant's email",
  "Invitation accepté": "Invitation accepted",
  "Détails du comptable": "Accountant details",
  "Dissocier": "Dissociate",
  "Disassociate": "Disassociate",
  "Chargement...": "Loading...",
  "Notifications": "Notifications",
  "Qui êtes-vous ?": "Who are you?",
  "Choisissez le statut qui vous décrit le mieux ": "Choose the status that best describes you ",
  "Un Cabinet comptable": "An Accounting Firm",
  "Choisissez cette option si vous êtes un commissaire au compte, expert comptable ou comptable agrée dans un cabinet, gérant plusieurs clients.": "Choose this option if you are a statutory auditor, chartered accountant or certified accountant in a firm, managing multiple clients.",
  "Une entreprise": "A company",
  "Choisissez cette option si vous êtes un entrepreneur, ou un cadre comptable et financier interne dans une entreprise. ": "Choose this option if you are an entrepreneur, or an internal accounting and finance executive in a company.",
  "Suivant": "Next",
  "Vous recherchez un comptable ?": "Are you looking for an accountant?",
  "Company?": "Company?",
  "Rejoindre": "Join",
  "Veuillez fournir les informations suivantes pour garantir une expérience d'intégration fluide": "Please provide the following information to ensure a smooth onboarding experience",
  "Numéro d'agrément": "Approval number",
  "Wilaya": "Wilaya",
  "Combien de clients prévoyez-vous de gérer ?": "How many clients do you plan to manage?",
  "Retour": "Back",
  "Vous recherchez un client ?": "Are you looking for a client?",
  "Accountant?": "Accountant?",
  "Rejoignez-nous": "Join us",
  "Embarquez dans un processus d'intégration fluide pour améliorer votre expérience avec nous": "Embark on a smooth onboarding process to enhance your experience with us",
  "Taille de l'agence": "Agency size",
  "skip": "skip",
  "Tous les cercles": "All circles",
  "Non classé": "Unclassified",
  "Nouveau cercle": "New circle",
  "Ajouter un membre": "Add a member",
  "Êtes-vous sûr de vouloir supprimer le membre ": "Are you sure you want to delete the member ",
  "le cercle": "the circle",
  "vient d'être créé": "has just been created",
  "vient d'être edité": "has just been edited",
  "vient d'être supprimé": "has just been deleted",
  "le membre": "the member",
  "vient d'être détaché du circle": "has just been detached from the circle",
  "Paramètres du compte": "Account settings",
  "Détails du compte": "Account details",
  "Nom complet": "Full name",
  "N° du Téléphone": "Phone number",
  "Sécurité": "Security",
  "Old password": "Old password",
  "New password": "New password",
  "Confirm new password": "Confirm new password",
  "Change password": "Change password",
  "Êtes-vous sûr de vouloir supprimer ce compte ? La suppression de ce compte entraînera la suppression de toutes les données qui y sont associées.": "Are you sure you want to delete this account? Deleting this account will result in the deletion of all associated data.",
  "Liste des catégories": "List of categories",
  "Nouvelle catégorie": "New category",
  "All fields": "All fields",
  "Il s'agit d'une catégorie par défaut qui appartient au système": "This is a default category that belongs to the system",
  "Champs de catégorie": "Category fields",
  "Tous les champs": "All fields",
  "Ces champs appartiennent à toutes les catégories existantes sur la plateforme.": "These fields belong to all existing categories on the platform.",
  "Ajouter un champ": "Add a field",
  "Connected": "Connected",
  "Last uplaod at": "Last upload at",
  "Decconecter": "Disconnect",
  "Connecter": "Connect",
  "Modifier la catégorie": "Edit category",
  "Nom de la catégorie": "Category name",
  "statement_row": "statement_row",
  "statement_desc": "statement_desc",
  "statement": "statement",
  "disable": "disable",
  "enable": "enable",
  "create": "create",
  "Êtes-vous sûr de vouloir supprimer la catégorie": "Are you sure you want to delete the category",
  "Modifier le champ": "Edit field",
  "Nouveau champ": "New field",
  "Nom du champ": "Field name",
  "Type de champ": "Field type",
  "Entrez vos options": "Enter your options",
  "Sélectionnez les catégories": "Select categories",
  "Êtes-vous sûr de vouloir supprimer le champ": "Are you sure you want to delete the field",
  "Detach Field": "Detach Field",
  "Êtes-vous sûr de vouloir détacher le champ": "Are you sure you want to detach the field",
  "de la catégorie": "from the category",
  "Texte": "Text",
  "Zone de texte": "Text area",
  "Sélection": "Selection",
  "Type": "Type",
  "Options": "Options",
  "Catégories": "Categories",
  "Integrations": "Integrations",
  "integration failed try again later or contact support": "integration failed try again later or contact support",
  "integration avec": "integration with",
  "fait avec success": "done successfully",
  "annulée": "cancelled",
  "export": "export",
  "Aucune relevé traitée pour le moment": "No statements processed at the moment",
  "Modifier": "Edit",
  "Certaines données peuvent être manquantes ou incorrectes": "Some data may be missing or incorrect",
  "Ce document doit être vérifié par le comptable": "This document must be verified by the accountant",
  "Échec": "Failure",
  "En attente": "Pending",
  "Alerte": "Alert",
  "Catégorie supprimée": "Category deleted",
  "Traité": "Processed",
  "Êtes-vous sûr de vouloir supprimer le relevé": "Are you sure you want to delete the statement",
  "La page ne peut pas être consultée sur la version mobile": "The page cannot be viewed on the mobile version",
  "Le journal n'est pas disponible sur mobile. Veuillez vous rendre sur votre navigateur Web pour le consulter": "The journal is not available on mobile. Please go to your web browser to view it",
  "Revenir au dashboard": "Return to dashboard",
  "Référence": "Reference",
  "Total": "Total",
  "Compte": "Account",
  "Libellé": "Label",
  "Débit": "Debit",
  "Crédit": "Credit",
  "un autre utilisateur a supprimé un relevé(s)": "another user has deleted statement(s)",
  "Modifier le document": "Edit document",
  "Répertoire du document": "Document directory",
  "Champs": "Fields",
  "Récupération du document...": "Retrieving document...",
  "Document enregistré": "Document saved",
  "Détails du document": "Document details",
  "Entamez une discussion": "Start a discussion",
  "Propriétaire": "Owner",
  "Date de téléchargement": "Upload date",
  "NOUVEAU": "NEW",
  "Uplaod": "Upload",
  "Personnalisez votre environnement": "Customize your environment",
  "Personnalisez votre tableau de bord pour correspondre à vos préférences de flux de travail. Une fois votre  espace de travail préparé, vous débloquerez tout le potentiel de la plateforme.": "Customize your dashboard to match your workflow preferences. Once your workspace is prepared, you'll unlock the full potential of the platform.",
  "Personnaliser": "Customize",
  "Documents partagés": "Shared documents",
  "Vous avez téléchargé avec succès": "You have successfully uploaded",
  "Pour terminer le processus, veuillez fournir les informations nécessaires concernant vos documents.": "To complete the process, please provide the necessary information regarding your documents.",
  "ce dossier a été supprimée par un autre utilisateur": "this folder has been deleted by another user",
  "un autre utilisateur a supprimé un(des) dossier(s)": "another user has deleted folder(s)",
  "un autre utilisateur a supprimé un(des) fichier(s)": "another user has deleted file(s)",
  "Documents non classés": "Unclassified documents",
  "documents sélectionnés": "selected documents",
  "Traiter avec l'IA": "Process with AI",
  "Changer l'emplacement": "Change location",
  "Process file": "Process file",
  "document": "document",
  "move": "move",
  "delete": "delete",
  "Emplacement": "Location",
  "Traitement de l'IA": "AI Processing",
  "un autre utilisateur a supprimé un(des) document(s)": "another user has deleted document(s)",
  "More options": "More options",
  "Invites": "Invites",
  "My accountant": "My accountant",
  "Détails de l'utilisateur": "User details",
  "Numéro de téléphone": "Phone number",
  "Entreprises affiliées": "Affiliated companies",
  "Dissociation comptable": "Accounting unbundling",
  "Dissocier le comptable": "Disassociate the accountant",
  "Supprimer l'entreprise": "Delete the company",
  "en supprimant cette entreprise, vous perdrez toutes les données qui lui sont associées": "by deleting this company, you will lose all the data associated with it",
  "Êtes-vous sûr de vouloir supprimer cette entreprise ?": "Are you sure you want to do away with this company?",
  "Annuler": "Cancel",
  "Confirmer": "Confirm",
  "Inviter": "Invite",
  "Desassocier": "Desassociate",
  "Entreprise Id": "Company Id",
  "Nom": "Name",
  "Actions": "Actions",
  "Supprimer le cabinet comptable": "Eliminate the accounting firm",
  "En supprimant ce cabinet comptable, vous perdrez le contact avec vos clients et éventuellement avec vos comptables.": "By eliminating this accounting firm, you will lose contact with your customers and possibly with your accountants.",
  "Êtes-vous sûr de vouloir supprimer ce cabinet comptable ?": "Are you sure you want to do away with this accounting firm?",
  "Cancel": "Cancel",
  "Save changes": "Save changes",
  "Éditer": "Edit",
  "Invite": "Invite",
  "invite": "invite",
  "s'est dissocié de": "has dissociated itself from",
  "Inviter un comptable pour votre cabinet": "Invite an accountant to your practice",
  "Envoyez ce lien au comptable que vous souhaitez inviter": "Send this link to the accountant you wish to invite",
  "Choisir un cabinet comptable": "Choosing an accounting firm",
  "Vous n'avez pas encore de cabinet comptable, créez-en une pour commencer.": "If you don't yet have an accounting firm, start one now.",
  "précédent": "previous",
  "générer un lien": "generate a link",
  "Lien à partager": "Link to share",
  "Envoyer par email": "Send by email",
  "Envoyer": "Send",
  "Email envoyé": "Email sent",
  "Inviter une entreprise pour votre cabinet comptable": "Invite a company to your accounting firm",
  "Envoyez ce lien au entreprise que vous souhaitez inviter": "Send this link to the company you wish to invite",
  "Inviter un cabinet comptable": "Invite an accounting firm",
  "Inviter un cabinet comptable pour votre entreprise": "Inviting an accounting firm to your business",
  "Envoyez ce lien au cabinet comptable que vous souhaitez inviter": "Send this link to the accounting firm you wish to invite",
  "Choisir une entreprise": "Choose a company",
  "Vous n'avez pas encore d'entreprise, créez-en une pour commencer.": "You don't have a company yet, create one now to start.",
  "Dashboard": "Dashboard",
  "Inviter un cabinet": "Invite a firm",
  "Inviter une entreprise": "Invite a company",
  "Inviter un comptable": "Invite an accountant",
  "Rechercher les enreprises": "Search for companies",
  "Mes entreprise": "My companies",
  "Accéder": "Go to",
  "Profile": "Profile",
  "Companies": "Companies",
  "Firms": "firms",
  "Users": "Users",
  "Ajouter une agence de comtabilite": "Add an accountancy agency",
  "invité": "invited",
  "documents without currency will not appear in this filtering": "documents without currency will not appear in this filtering",
  "Export statements": "Export statements",
  "Filter by currency": "Filter by currency",
  "currency": "currency",
  "default currency": "default currency",
  "Êtes-vous sûr de vouloir supprimer l'entreprise? La suppression de l'entreprise entraînera la suppression de toutes les données qui y sont associées.": "Are you sure you want to delete the company? Deleting the company will result in the deletion of all associated data.",
  "Ajouter": "Add",
  "choisissez un cabinet comptable": "Choose an accounting firm",
  "choisissez une entreprise": "Choose a company",
  "Sélectionner un cabinet comptable pour l'attribuer à l'entreprise": "Select an accounting firm to assign to the company",
  "choisir une entreprise pour travailler avec votre cabinet comptable": "Choose a company to work with your accounting firm",
  "Install mobile app": "Install mobile app",
  "For a smoother experience": "For a smoother experience",
  "Get app": "Get app",
  "Ce comptable perdra tout accès. Si vous souhaitez travailler avec lui, vous devrez l'inviter à nouveau. Continuer ?": "This accountant will lose all access. If you wish to work with him, you will need to invite him again. Continue?",
  "Saisir l'adresse électronique": "Enter e-mail address",
  "Nombre du clients": "Clients number",
  "cabinet comptable supprimé avec succès": "Accounting firm successfully deleted",
  "Currency": "Currency",
  "général": "General",
  "Invitation": "Invitation",
  "Veuillez choisir d'accepter ou de refuser l'invitation": "Please choose to accept or decline the invitation",
  "Details": "Details",
  "Choisissez un mot de passe robuste afin de protéger vos informations et votre vie privée": "Choose a strong password to protect your information and privacy",
  "Save": "Save",
  "Entreprise": "Company",
  "Avertissement": "Warning",
  "Settings": "Settings",
  "Archive": "Archive",
  "Entreprises associées":"Associated companies",
  "Mes cabinets":"My firms",
  "Cabinets associés":"Associate firms",
  "No statements available":"No statements available",
  "No file has been processed yet":"No file has been processed yet",
  "Ajouter une entreprise": "Add a company",
  "Ajouter une agence de comptabilité": "Add an accounting firm",
  "Email de l'entreprise": "Company's email",
  "{count} file(s) uploaded successfully":"{count} file(s) uploaded successfully",
  "Seen by": "Seen by",
  "Delivered": "Delivered",
  "User Id": "User Id",
}