import { useCompanyStore } from "src/stores/company";
export default function hasAccountant(has) {
  if(has) {
    return async function({store, next}) {
      if (! useCompanyStore().getCurrentCompany?.accountant?.id) {
        next({name: "home"});
      }
      return next();
    }
  }

  return async function({store, next}) {
    if ( useCompanyStore().getCurrentCompany?.accountant?.id) {
      next({name: "company.accountant"});
    }
    return next();
  }
}
