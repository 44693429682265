import {defineStore} from 'pinia'

export const usePreferencesStore = defineStore('preferences', {
  state: () => ({
    preferences: {
      view: 'cards'
    }
  }),

  getters: {
    getPreferences(state) {
      return state.preferences
    }
  },

  actions: {
    async setPreferences(key, value) {
      this.preferences = {... this.preferences, [key]:  value}
    }
  }
})
