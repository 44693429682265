export default async function auth({ store, next }) {
  let paths = window.location.href.split("/");
  let invitations = paths.find(path => path === 'invitations');
  let invitationToken = null;
  if (paths.indexOf(invitations) === paths.length - 2) {
    invitationToken = paths[paths.length - 1];
  }

  if (!store.user) {
    if (invitationToken) window.location = "/login?invitation_token=" + invitationToken;
    else
      window.location = "/login";

    return
  }
  // todo email verification
  if (!store.user.email_verified_at) {
    window.location = "/verify-email"
    return
  }

  return next();
}
