import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'
import middlewarePipeline from '../middleware/middlewarePipeline';
import { useUserStore } from '../stores/user';
import { useCompanyStore } from "../stores/company";
import { useThreadStore } from 'src/stores/thread';
import { useFirmStore } from 'src/stores/firm';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */



const createHistory = process.env.SERVER
  ? createMemoryHistory
  : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

const Router = createRouter({
  scrollBehavior: () => ({left: 0, top: 0}),
  routes,

  // Leave this as is and make changes in quasar.conf.js instead!
  // quasar.conf.js -> build -> vueRouterMode
  // quasar.conf.js -> build -> publicPath
  history: createHistory(process.env.VUE_ROUTER_BASE)
})

Router.beforeEach(async (to, from, next) => {
  //todo too slow  ? remove
  const userStore = useUserStore();
  const companyStore = useCompanyStore();

  if (! ['/', 'login', 'register'].includes(to)) {

    await userStore.setUser();
    if (to.params.company) {

      //set current company to store
      const companyId = to.params.company;
      companyStore.setCurrentCompanyId(companyId);

      // init threads
      if (companyId !== from.params.company) {
        const threadStore = useThreadStore();
        if (companyId) {
          await threadStore.InitThreads(companyId, null, to.params.archived);
        } else {
          threadStore.CleanStore();
        }
      }
    }

    if (to.params.firmId) {
      if (useFirmStore().getCurrentFirm?.id)
        useFirmStore().setCurrentFirm(to.params.firmId);
      else
        await useFirmStore().setCurrentFirm(to.params.firmId);
    }
  }

  const context = {
    to,
    from,
    store: userStore,
    next,
  };

  let middlewareSet = new Set()
  to.matched.forEach(match => {
    if (match.meta?.middleware)
      match.meta?.middleware.forEach(middleware => middlewareSet.add(middleware))
  })
  if (middlewareSet.size === 0) {
    return next();
  }

  const middlewares = Array.from(middlewareSet)

  return await middlewares[0]({
    ...context,
    next: middlewarePipeline(context, middlewares, 1),
  });
});


export default Router;